import React from "react";
import { Plan } from "@rtm-ui/plan";

export function Offer({ state, formRef, ...rest }) {
  return (
    <Plan
      {...rest}
      plan={{
        ...state.campaign.content,
        has_phoneback: state.campaign.has_phoneback,
        main_image_file_url: state.references.main_image_file_url,
        mobile_image_file_url: state.references.mobile_image_file_url,
        multi_image_file_urls: state.references.multi_image_file_urls
      }}
      authenticityToken={state.userToken}
      userApiAuthToken={state.userApiAuthToken}
      reference={state.references}
      actions={state.campaign.actions.map(action => {
        if (action.actionType === "back") {
          return {
            ...action,
            onClick: () => {
              formRef.current.scrollIntoView({
                behavior: "smooth"
              });
            }
          };
        }
        return action;
      })}
    />
  );
}
