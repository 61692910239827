import styled from "styled-components";
import { Box } from "@rtm-ui/layout";

export const FormWrapper = styled(Box)`
  width: 100%;
  background: white;

  & > * {
    background: white;
    margin: 0 auto;
    max-width: 400px;
  }
`;
