import "react-app-polyfill/stable";
import React from "react";
import { Bootstrap } from "@rtm-ui/bootstrap";
import { LocaleProvider } from "@rtm-ui/i18n";

export const Main = ({ trackingData, themeName, children }) => {
  return (
    <Bootstrap themeName={themeName} trackingData={trackingData}>
      <LocaleProvider keys={{ errorMessage: "Oh no!" }}>
        {children}
      </LocaleProvider>
    </Bootstrap>
  );
};
