/* eslint-disable no-use-before-define */
import React from "react";
import { Box } from "@rtm-ui/layout";
import { Form } from "@rtm-ui/form";
import { track } from '@rtm-ui/tracker';
import { saveSelectedData } from "../common/userProfile";
import { AppStateContext, AppDispatchContext } from "../app-context";
import * as S from "./styles";
import { fieldLabels, SuburbInput } from "../utils";
import { getFields, campaignQuery, sendSurvey, getUserInfo } from "../handlers";

export const Energy = ({ formRef: ref, campaignType, askPostcode }) => {

  const state = React.useContext(AppStateContext);
  const dispatch = React.useContext(AppDispatchContext);
  const currentPostcodeSuburb = state.user && state.user.postcodeSuburb;
  /**
   * After multi energy campaign implementation, the available routes are
   * /energy
   * /energy/business
   * all the energy routes should resolve `energy` as the currentProduct
  */
  const currentProduct = state.currentProduct;

  React.useEffect(() => {
    const prepareForm = async () => {
      if (currentPostcodeSuburb && currentProduct) {
        dispatch({ type: "formBuilding" });
        try {
          const fields = await getFields(
            dispatch,
            currentProduct,
            currentPostcodeSuburb,
            state.user.email,
            campaignType
          );
          const customerField = (fields || []).filter(f => f.name === 'existingCustomer');
          const regularFields = (fields || []).filter(f => f.name !== 'existingCustomer');
          // If each field is hidden, we don't need to wait for
          // the user to submit the form, just search now
          if (fields.length === 0) {
            dispatch({ type: "formFailed" });
          } else if (
            fields.length > 0 &&
            fields.every(field => field.type === "hidden")
          ) {
            dispatch({ type: "postCodeEmptyMessage" }); // For single options, it displays empty message
            runCampaignQuery(fields);
          } else if (fields.length > 0 && customerField.length > 0) {
            if (customerField[0].config.offerAvailable) {
              dispatch({ type: "formReady", fields, formReady: true });
            } else {
              dispatch({ type: "form1Ready", fieldset1: customerField, fieldset2: regularFields });
            }
          } else {
            dispatch({ type: "formReady", fields, formReady: true });
          }
        } catch (e) {
          dispatch({ type: "formFailed" });
        }
      }
    };
    prepareForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPostcodeSuburb, runCampaignQuery, currentProduct]);


  // Memoized campaign query
  const runCampaignQuery = React.useCallback(
    async (values = []) => {
      if (values.length > 0) {
        const data = { email: state.user.email, data: {} }
        values.forEach((value) => {
          if (value.name === 'fuelType') {
            data.data.fuelType = value.value.split(',').join(' and ');
          }
          if (value.name === 'solar') {
            data.data.solarType = value.value === 'true' ? 'solar' : 'non solar';
          }
        });
        const user = await getUserInfo(state.userApiAuthToken)
        if (user && !user.is_suppressed) {
          sendSurvey(data);
        }
      }
      campaignQuery(dispatch, state, values);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPostcodeSuburb, currentProduct]
  );
  const trackingData = {
    category: 'energy',
    meta: state.trackingMeta || {
      campaign_type: '',
      state: state.theme.countryCode === 'AU' ? (state.user.state || 'AU') : state.theme.countryCode,
      email: state.user.email,
      postcode: state.user.postcodeSuburb,
    }
  }

  // call tracker is only called if user clicks the icon
  const callTracker = (field, value) => {  // field = name of input field, value = user selected value

    let action = field;
    if (field === 'existingCustomer') {
      action = `${field}_tile_button`
      trackingData.meta['existing_customer'] = value === 'true' ? 'isexistingcustomer' : 'isnewcustomer'
    }
    if (field === 'fuelType') {
      trackingData.meta['plan_type'] = value === 'electricity,gas' ? 'EG' : 'E' // plantype 'G' will not be user clickable as it doesnt have 2nd option
      action = `${field}_tile_button`
    }
    if (field === 'solar') {
      if (state.form && state.form.fields && state.form.fields[0]
        && state.form.fields[0].options && state.form.fields[0].options.length === 1) {
        trackingData.meta['plan_type'] = state.form.fields[0].options[0].value === 'electricity,gas' ? 'EG' : 'E'  // tile is automatically selected if there is only one option
      }
      trackingData.meta['is_solar'] = value === 'true'
      action = `${field}_tile_button`
    }
    track(action, trackingData);
  }

  return (
    <S.FormWrapper px={[0, 2]} py={10}>
      {/* For now set this so it matches up with plan cta right-align */}
      <Box px={[2]} py={30}>
        <div ref={ref}>
          <SuburbInput state={state} />
        </div>
        {/* FIXME: this needs to re-render when the fields change */}

        {state.form1Ready && (
          <>
            <Form
              {...state.form1}
              progressiveReveal={true}
              renderFooter={() => null}
              autoSearch
              getNewestFieldValue={(field, value) => {
                callTracker(field, value);
                saveSelectedData(field, value, state.campaign_id, state.userToken);
              }}
              onSubmit={async values => {
                const existingCustomer = values.filter(v => v.name === 'existingCustomer' && v.value === 'true')[0]
                if (existingCustomer && !existingCustomer.config.offerAvailable) {
                  const hint = (existingCustomer.options || []).filter(o => o.value === 'true')[0].hint
                  dispatch({ type: "offerNotAvailableForExistingCustomers", offerErrorMessage: hint });
                }  else {
                  dispatch({ type: "formReady", formReady: true, fields: state.fieldset2 });
                  dispatch({ type: "trackingMeta", trackingMeta: trackingData.meta });
                  if (state.fieldset2.length == 1 && state.fieldset2[0].type == "hidden") {
                    let mergedValues = [...values, ...state.fieldset2];
                    runCampaignQuery(mergedValues);
                  }
                }
                return false;
              }}
            />
          </>
        )}
        {state.formReady ? (
          <Form
            {...state.form}
            id="campaign-query"
            progressiveReveal={true}
            renderFooter={() => null}
            autoSearch
            getNewestFieldValue={(field, value) => {
              callTracker(field, value);
              saveSelectedData(field, value, state.campaign_id, state.userToken);
            }}
            onSubmit={async values => {
              dispatch({ type: "trackingMeta", trackingMeta: trackingData.meta });
              runCampaignQuery(values);
              return values;
            }}
          />
        ) : currentPostcodeSuburb ? (
          state.postCodeMessage
        ) : (
          `Please provide your ${fieldLabels[state.theme.countryCode].friendlyname}`
        )}
      </Box>
    </S.FormWrapper>
  );
};
