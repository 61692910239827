export const settings = () => {
  let { host, protocol } = window.location;
  let api_protocol = protocol;
  let api_host = host;
  if (api_host.startsWith("www")) {
    api_host = api_host.slice(4);
  }
  if (host.includes("localhost")) {
    api_protocol = "https://";
    api_host = "onebigswitch.com.au";
    host = "obsau.develop:3000";
  }
  if (host.includes("amazon")) {
    api_host = "onebigswitch.com.au";
    host = "staging.onebigswitch.com.au";
  }

  return {
    themeEndpoint: `${api_protocol}//api.${api_host}/theme`,
    metaEndpoint: `${protocol}//${host}/api/meta`,
    baseApiEndpoint: `${protocol}//${host}/api`,
    planApiEndpoint: `${protocol}//${host}/api/plans`,
    fieldEndpoint: `${protocol}//${host}/api/plans/energy/options`,
    surveyApiEndpoint: `${protocol}//api.${host}/survey`,
    baseDomain: `${protocol}//${host}`,
    authenticateEndpoint: `${protocol}//${host}/api/v2/authenticate`,
    userEndpoint: `${protocol}//${host}/api/v2/user`
  };
};


//  LOCAL AU OBS

// export const settings = () => ({
//   themeEndpoint: "https://api.onebigswitch.com.au/theme",
//   planApiEndpoint: 'http://obsau.develop:3000/api/plans',
//   metaEndpoint: "http://obsau.develop:3000/api/meta",
//   baseApiEndpoint: "http://obsau.develop:3000/api",
//   fieldEndpoint: "http://obsau.develop:3000/api/plans/energy/options",
//   zoneDataEndpoint: "https://api.onebigswitch.com.au/zone-data",
//   surveyApiEndpoint: 'https://api.staging.onebigswitch.com.au/survey',
//   baseDomain: 'http://obsau.develop:3000',
//   authenticateEndpoint: 'http://obsau.develop:3000/api/v2/authenticate'
// });

//   Staging OBS IE

// export const settings = () => ({
//   themeEndpoint: "https://api.onebigswitch.ie/theme",
//   metaEndpoint: "https://staging.onebigswitch.ie/api/meta",
//   baseApiEndpoint: "https://staging.onebigswitch.ie/api/plans",
//   zoneDataEndpoint: "https://api.staging.onebigswitch.ie/zone-data",
//   postcodeEndpoint: "https://api.resources.revtech.media/postcode",
//   surveyApiEndpoint: `https://api.staging.onebigswitch.com.au/survey`
// });


// AU OBS production

// export const settings = () => ({
//   themeEndpoint: "https://api.onebigswitch.com.au/theme",
//   metaEndpoint: "https://onebigswitch.com.au/api/meta",
//   baseApiEndpoint: "https://onebigswitch.com.au/api/",
//   planApiEndpoint: `https://onebigswitch.com.au/api/plans`,
//   zoneDataEndpoint: "https://api.onebigswitch.com.au/zone-data",
//   postcodeEndpoint: "https://api.resources.revtech.media/postcode",
//   surveyApiEndpoint: `https://api.onebigswitch.com.au/survey`,
//   baseDomain: `https://onebigswitch.com.au`
// });
