import React from "react";
import { Form } from "@rtm-ui/form"
import { AppDispatchContext } from "./app-context";
import { searchFunction } from "./handlers";

const fieldLabels = {
  AU: {
    label: "My Postcode:",
    name: "postcode",
    hint: "Eg. 2000, Barangaroo",
    friendlyname: "Postcode"
  },
  US: {
    label: "My Zipcode:",
    name: "zipcode",
    hint: "Ex. 51111, Sioux City",
    friendlyname: "Zipcode"
  },
  IE: {
    label: "My County:",
    name: "zipcode",
    hint: "Eg. Carlow",
    friendlyname: "County"
  },
  UK: {
    label: "My Postcode:",
    name: "postcode",
    hint: "Eg. AB10, City of Aberdeen",
    friendlyname: "Postcode"
  }
};

export const SuburbInput = ({ state }) => {
  const dispatch = React.useContext(AppDispatchContext);
  const suburbField = {
    onEmptyResult: () => { },
    component: "autocomplete",
    searchFunction: async searchTerm =>
      searchFunction(state.postcodeUrl, searchTerm, state.theme.countryCode)
  };

  const suburbonEmptyResult = () => {
    // reset the currentPostcode suburb value
    dispatch({ type: "formFailed", value: null });
  }
  const checkSuburbFields = (countryCode, initialValue) => {
    const data = {
      ...fieldLabels[countryCode],
      initialValue,
      type: "text",
      autoComplete: "off",
      config: {
        ...suburbField,
        scrollable: true,
        validator: fieldLabels[countryCode].friendlyname.toLowerCase(),
        onDidSelect: (_, label, selected) => { dispatch({ type: "setPostcode", value: label, state: selected.state }) },
        onEmptyResult: suburbonEmptyResult
      }
    };
    return data;
  };

  return (
    <Form
      id="postcode-search"
      onSubmit={async () => { }}
      fields={
        [
          checkSuburbFields(
            state.theme.countryCode,
            state.user.postcodeSuburb
          )
        ]}
      renderFooter={() => null}
    />
  )
};

export const titleCase = (str) => {
  return str
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
}

export { fieldLabels };
