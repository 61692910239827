import styled from "styled-components";
import { Box } from "@rtm-ui/layout";

export const SearchBoxWrapper = styled(Box)`
  background: white;
  min-height: 500px;
  position: relative;
`;

export const SearchBox = styled(Box)`
  position: "absolute";
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: "#fff";
`;

export const LoadingPageWrapper = styled(Box)`
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

export const LoadingPageMiddle = styled(Box)`
  display: table-cell;
  vertical-align: middle;
`

export const LoadingPageInner = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  width: 400px;
`

export const InfoContainer = styled(Box)`
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
`
