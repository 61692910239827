import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { LocaleProvider } from "@rtm-ui/i18n";
import definitions from "./definitions";
import { Location } from "@reach/router"

ReactDOM.render(
  <LocaleProvider keys={definitions}>
    <Location>
    <App />
    </Location>
  </LocaleProvider>,
  document.getElementById("root")
);
