import { Header, Small } from "@rtm-ui/typography";
import axios from "axios";
import qs from "qs";
import React from "react";
import { settings } from "./settings";

export const getTheme = async () => {
  return await axios
    .get(settings().themeEndpoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .then(response => {
      return response.data;
    }).catch(() => {
      return false;
    });
};

export const getUserSession = async cookies => {
  let session_var = false;
  const queryParams = qs.parse(window.location.search.substring(1));
  if (cookies.user_email) {
    const suburb = cookies.user_suburb;
    const cookiesSuburb = decodeURIComponent(suburb.replace(/\+/g, "%20"));
    session_var = {
      email: cookies.user_email,
      postcodeSuburb: cookiesSuburb,
      state: cookies.user_state
    };
  } else if (queryParams.email) {
    session_var = {
      email: queryParams.email,
      postcodeSuburb: queryParams.postcode,
      state: queryParams.state
    };
  } else {
    // do nothing and return false
  }
  return session_var;
};

export const getAuthToken = async (user) => {
  // Call the api
  let data = {
    email: user.email,
    postcode_suburb: user.postcodeSuburb
  };
  return await axios
    .post(
      settings().authenticateEndpoint,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    ).then(response => {
      const { auth_token } = response.data;
      return auth_token;
    })
    .catch(() => {
      return false;
    });
};

export const getUserInfo = async (authToken) => {
  return await axios
    .get(
      settings().userEndpoint,
      {
        headers: {
          "Content-Type": "application/json",
          'X-Authorization': authToken
        }
      }
    ).then(response => {
      const { user } = response.data;
      return user;
    })
    .catch(() => {
      return false;
    });
};

export const getMeta = async (product) => {
  const queryHash = { product: product };
  return await axios
    .get(`${settings().metaEndpoint}?${constructQueryParam(queryHash)}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
};

/*
* Runs /api/plans/:{product}/query
* Returns : plans and zonedata
*/
export const campaignQuery = async (dispatch, state, values = []) => {

  const currentPostcodeSuburb = state.user && state.user.postcodeSuburb;
  dispatch({ type: "searching" });
  let postcode = null;
  if (currentPostcodeSuburb) {
    postcode = currentPostcodeSuburb.split(",")[0];
  }

  try {
    let params = {
      postcode, email: state.user.email, campaign_type: state.campaignType,
      ...values.reduce((ret_obj, item) => {
        if (item.name !== 'renewalMonthQuestion') {
          ret_obj[item.name] = item.value;
        }
        return ret_obj;
      }, {})
    };

    const [campaign, zoneData] = await getCampaign(state.currentProduct, params);

    if (
      Object.keys(campaign).length !== 0 &&
      Object.keys(zoneData).length !== 0
    ) {
      dispatch({ type: "offerReady", authToken: state.user.authToken, offerReady: true, campaign, zoneData });
    } else {
      dispatch({ type: "offerNotAvailable" });
    }
  } catch (e) {
    dispatch({ type: "offerNotAvailable" });
  }
};

/**
 *
 * @param queryHash
 * remove hash with empty keys, combine and return get query string
 */
const constructQueryParam = (queryHash => {
  return Object.keys(queryHash).reduce((ret_obj, current) => {
    if (queryHash[current] !== undefined && queryHash[current] !== "") {
      ret_obj.push(`${current}=${queryHash[current]}`);
    }
    return ret_obj;
  }, []).join('&');
});

/*
* Runs /api/plans/:{product}/options
* Returns : fields to be populated for questions
*/
export const getFields = async (dispatch, product, postcodeSuburb = null, email, campaignType) => { // TODO: REMOVE ONCE BACKEND IS READY
  let queryHash = {};
  if (postcodeSuburb) {
    const postcode = postcodeSuburb.split(",")[0];
    queryHash = { postcode, email, campaign_type: campaignType };
  } else {
    queryHash = { email, campaign_type: campaignType };
  }
  // TODO: this should work with the full postcode suburb
  const zoneFields = await axios
    .get(
      `${settings().planApiEndpoint}/${product}/options?${constructQueryParam(queryHash)}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    )
    .then(response => {
      return response.data;
    }).catch(error => {
      console.log(error);
      return { fields: [] };
    });

  dispatch({ type: "setUserProfile", campaign_id: zoneFields.campaign_id });

  return (
    zoneFields.fields &&
    zoneFields.fields.map(field => {
      let overrides = {};
      if (field.options && field.options.length === 1) {
        overrides = {
          type: "hidden",
          value: field.options[0].value,
          initialValue: field.options[0].value
        };
      }
      return {
        ...field,
        ...overrides,
        config: {
          ...field.config,
          component: field.component,
          validator: field.validator || "requiredRadio",

          justifyContent: "center",
          renderLabel: ({ name, label, description }) => (
            <>
              <Header mt={10} tag="h5" align="center" htmlFor={name}>
                {label}
              </Header>
              <div style={{ textAlign: "center" }}>
                <Small align="center">{description}</Small>
              </div>
            </>
          )
        }
      };
    })
  );
};


export const sendSurvey = async (data) => {
  await axios
    .post(
      settings().surveyApiEndpoint,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    );
};

export const sendUserProfile = async (data) => {
  await axios
    .post(
      `${settings().baseApiEndpoint}/user_profile`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    ).then(response => {
      return response.data;
    }).catch(error => {
      console.log(error);
      return error;
    });
};

export const getCampaign = async (product, queryObject) => {
  return axios
    .get(`${settings().planApiEndpoint}/${product}/query?${constructQueryParam(queryObject)}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .then(response => {
      const { zoneData, ...campaign } = response.data;
      return [campaign, zoneData];
    });
};

export const validateUserToken = async (token) => {
  return axios
    .get(`${settings().baseDomain}/csrf/validate?authenticityToken=${token}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .then(response => {
      const { validationResult } = response.data;
      if (!validationResult) {
        getUserToken();
      }
      return validationResult;
    });
};

export const getUserToken = async () => {
  return axios
    .get(`${settings().baseDomain}/csrf/generateToken`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .then(response => {
      const { authenticityToken } = response.data;
      return authenticityToken;
    }).catch((error) => {
      console.log("CSRF Error", error);
    });
};

/*
* Handler for postcode search field
*/
export const searchFunction = async (postcodeUrl, searchTerm, countryCode) => {
  const pageSize = 100; // Initially set the postcode results limit to 100
  let postCode = "";
  if (countryCode === "IE" || countryCode === "UK") {
    postCode = searchTerm.toUpperCase(); // Postcode API suppports only county
  } else {
    postCode = searchTerm.match(/\d/g) || []; // Postcode API suppports only postcode (4 vs 5 digits) so validated only digits
    postCode = postCode.join("");
  }
  const result = await axios
    .get(
      `${postcodeUrl}/${postCode}?pageSize=${pageSize}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    )
    .then(response => {
      if (response && response.length === undefined) {
        const { data } = response;
        return data.postcodes.map(item => {
          let values = "";
          if (item.suburb !== undefined) {
            values = item.postcode + ", " + item.suburb;
          } else {
            values = item.postcode;
          }
          return {
            ...item,
            label: values
          };
        });
      }

    })
    .catch(() => {
      return [];
    });
  return result;
};
