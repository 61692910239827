import React from "react";
import { Box } from "@rtm-ui/layout";
import { Header, Text } from "@rtm-ui/typography";
import { A } from "@rtm-ui/a"
import * as S from "../energy/styles";

const NotFound = () => {
  return (
    <S.FormWrapper py={10}>
      <Box py={10}>
        <Header tag="h5" align="center" py={10}>
          OOPS! SORRY,
        </Header>
        <Header tag="h5" align="center" py={10}>
          THAT PAGE CANNOT BE FOUND
        </Header>
        <Text>
          Please <A onClick={window.zE && window.zE.activate({ hideOnClose: true })}>tell us</A> what you were trying to do and include your phone number if you're happy for us to contact you about this. Thanks!
        </Text>
      </Box>
    </S.FormWrapper >
  )
}

export default NotFound;
