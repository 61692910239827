import React from "react";
import { Box } from "@rtm-ui/layout";
import { Form } from "@rtm-ui/form";
import { track } from '@rtm-ui/tracker';
import { saveSelectedData } from "../common/userProfile";
import { AppStateContext, AppDispatchContext } from "../app-context";
import * as S from "./styles";
import { getFields, campaignQuery, sendSurvey, sendUserProfile } from "../handlers";

export const HomeAndCar = ({ formRef: ref, campaignType }) => {

  const state = React.useContext(AppStateContext);
  const dispatch = React.useContext(AppDispatchContext);
  const currentProduct = state.currentProduct;
  const currentPostcodeSuburb = state.user && state.user.postcodeSuburb;

  React.useEffect(() => {
    const prepareForm = async () => {
      if ( currentProduct) {
        dispatch({ type: "formBuilding" });
        try {
          const fields = await getFields(
            dispatch,
            currentProduct,
            currentPostcodeSuburb,
            state.user.email,
            campaignType
          );
          const fieldset1 = [fields[0]];
          const fieldset2 = [fields[1]];
          // If each field is hidden, we don't need to wait for
          // the user to submit the form, just search now
          if (fields.length === 0) {
            dispatch({ type: "formFailed" });
          } else {
            dispatch({ type: "form1Ready", fieldset1, fieldset2 });
          }
        } catch (error) {
          dispatch({ type: "formFailed" });
        }
      }
    };
    prepareForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ runCampaignQuery, currentProduct]);


  // Memoized campaign query
  const runCampaignQuery = React.useCallback(
    (values = []) => {
      // this is being done in the backend
      // if (values.length > 0) {
      //   sendSurvey({
      //      email: state.user.email,
      //      data: {
      //        [`${currentProduct}_${values[0].name}`]: values[0].value
      //      }
      //     });
      // }
      campaignQuery(dispatch, state, values);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentProduct]
  );

  const callTracker = (field, value) => {
    /* dont track if selected month is defaultmonth */
    if (state.form1 && state.form1.fields && state.form1.fields[0] && state.form1.fields[0].defaultValue === value) {
      return;
    }
   const action = `${field}_tile_button`;
   const category = currentProduct;
   const meta = {};
    if (value === '14') {
      meta[field] = 'dont-have';
    } else if (value === '13') {
      meta[field] = 'dont-know';
    } else {
      meta[field] = value;
    }
    track( action, {action, category, meta});
  }

  return (
    <S.FormWrapper px={[0, 2]} py={10}>
      {/* For now set this so it matches up with plan cta right-align */}
      <Box px={[2]} py={30}>
        {state.form1Ready && (
            <>
              <Form
                {...state.form1}
                progressiveReveal={true}
                renderFooter={() => null}
                autoSearch
                getNewestFieldValue = {(field, value) => {
                  callTracker(field,value);
                  saveSelectedData(field, value, state.campaign_id, state.userToken);
                }}
                onSubmit={async values => {
                  const customerKnowsMonth = values.filter(v => v.name === 'renewalMonthQuestion' && v.value === 'yes')[0]
                  if (customerKnowsMonth) {
                    dispatch({ type: "offerReady", offerReady: false});
                    if (state.form.fields.length < 1) {
                      dispatch({ type: "formReady", formReady: true, fields: state.fieldset2  });
                    } else {
                      dispatch({ type: "formReady", formReady: true, fields: state.form.fields  });
                    }
                  } else {
                    runCampaignQuery(values);
                    dispatch({ type: "formReady", formReady: false, fields: state.form.fields });
                    return values;
                  }
                  return false;
                }}
              />
            </>
          )}
        {state.formReady && (
          <Form
            {...state.form}
            id="campaign-query"
            progressiveReveal={true}
            renderFooter={() => null}
            getNewestFieldValue = {(field, value) => {
              callTracker(field,value);
              saveSelectedData(field, value, state.campaign_id, state.userToken);
            }}
            autoSearch
            onSubmit={async values => {
              runCampaignQuery(values);
              const fields = values;
              fields[0].defaultValue = values[0].value;
              dispatch({ type: "formReady", formReady: true, fields });
              return values;
            }}
          />
        )}
      </Box>
    </S.FormWrapper>
  );
};
