/* eslint-disable no-use-before-define */
import React from "react";
import { Box } from "@rtm-ui/layout";
import { Form, FormError } from "@rtm-ui/form";
import { track } from '@rtm-ui/tracker';
import { AppStateContext, AppDispatchContext } from "../app-context";
import * as S from "./styles";
import { getFields, campaignQuery, sendSurvey, getUserInfo } from "../handlers";

export const Health = ({ formRef: ref, campaignType }) => {

  const state = React.useContext(AppStateContext);
  const dispatch = React.useContext(AppDispatchContext);
  const currentProduct = state.currentProduct;
  const currentPostcodeSuburb = state.user && state.user.postcodeSuburb;

  React.useEffect(() => {
    const prepareForm = async () => {
      console.log('campaignType', campaignType);
      if (currentProduct) {
        dispatch({ type: "formBuilding" });
        try {
          const fields = await getFields(dispatch, currentProduct, currentPostcodeSuburb, state.user.email, campaignType);
          if (fields.length === 0) {
            runCampaignQuery()
            dispatch({ type: "formReady", formReady: true });
          } else {
            dispatch({ type: "form1Ready", fieldset1: [fields[0]] });
          }
        } catch (error) {
          dispatch({ type: "formFailed" });
        }
      }
    };
    prepareForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runCampaignQuery, currentProduct]);


  // Memoized campaign query
  const runCampaignQuery = React.useCallback(
    async (values = []) => {
      if (values.length > 0) {
        const data = {
          email: state.user.email,
          data: {
            healthInsuranceCoverType: values[0].value,
          }
        }
        const user = await getUserInfo(state.userApiAuthToken)
        if (user && !user.is_suppressed) {
          sendSurvey(data);
        }
      }
      campaignQuery(dispatch, state, values);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProduct]
  );

  // call tracker is only called if user clicks the icon
  const callTracker = (field, value) => {  // field = name of input field, value = user selected value
    const action = `coverType_tile_button`;
    const category = currentProduct;
    const meta = {};
    if(field === 'familyType') {
      meta['family_type'] = value;
    }
    track( action, { category, meta });
  }



  React.useEffect(() => {
     // if only one options run campaign query
  if (state.form1Ready) {
    const fields = state.form1.fields;
    if (fields.length  === 1) {
       const options = fields[0].options;
       if (options.length === 1 ) {
         runCampaignQuery(options);
       }
    }
   }
  }, [state.form1Ready]);


  return (
    <S.FormWrapper px={[20, 20]} py={0}>
      {/* For now set this so it matches up with plan cta right-align */}
      <Box px={[2]} py={30}>
        {state.form1Ready && (
          <>
            <Form
              {...state.form1}
              progressiveReveal={true}
              renderFooter={() => null}
              autoSearch
              getNewestFieldValue = {(field, value) => {
                callTracker(field,value);
              }}
              onSubmit={async values => {
                runCampaignQuery(values);
                const fields = values;
                fields[0].defaultValue = values[0].value;
                dispatch({ type: "formReady", formReady: true, fields });
                return values;
              }}
            />
          </>
        )}
      </Box>
    </S.FormWrapper>
  );
};
