import React from "react";
import { Link } from "@reach/router";
import { Box } from "@rtm-ui/layout";
import { Header, Text } from "@rtm-ui/typography";
import * as S from "../energy/styles";
import { AppDispatchContext } from "../app-context";
const Dashboard = (props) => {
  /* props["*"] contains nested path */
  const dispatch = React.useContext(AppDispatchContext);
  const product = props["*"];
  React.useEffect(() => {
    const product_campaign_split = product.split("/");
    dispatch({ type: "currentProduct", value: { currentProduct: product_campaign_split[0], campaignType: product_campaign_split[1] } })
  }, [product]
  );
  return (
    !product ?
      <S.FormWrapper px={[0, 2]} py={10}>
        <Box px={[0, 2]} py={10}>
          <Header tag="h5" align="center" padding={10}>
            Welcome {props.user.email}
          </Header>
          <Box>
            <Text align="center"> You might be interested in following offers</Text>
            <Box align="center" p={2}>
              <Link to="/offers/energy">Energy</Link> {" | "}
              <Link  to="/offers/broadband">Broadband</Link> {" | "}
              <Link  to="/offers/mobile">Mobile</Link> {" | "}
              <Link  to="/offers/health-insurance">Health</Link> {" | "}
              <Link to="/offers/home">Home</Link>{" | "}
              <Link to="/offers/car">Car</Link>
              <Link to="/offers/life-insurance">Life</Link>
            </Box>
          </Box>
        </Box>
      </S.FormWrapper >
      : props.children
  );
}

export default Dashboard;
