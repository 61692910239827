import React from "react";
import { Helmet } from "react-helmet";
import obsFavicon from "./obs-favicon.ico";
import ninesaverFavicon from "./ninesaver-favicon.ico";
import fiftyupFavicon from "./fiftyup-favicon.ico";
import { titleCase } from "./utils";

export const Seo = ({ product, themeName }) => {
  if (!themeName) {
    return null;
  }
  const themeMap = {
    obs: {
      favicon: obsFavicon,
      title: "One Big Switch"
    },
    ninesaver: {
      favicon: ninesaverFavicon,
      title: "9Saver"
    },
    fiftyup: {
      favicon: fiftyupFavicon,
      title: "Fifty Up Club"
    }
  };
  return (
    <Helmet>
      <link rel="shortcut icon" href={themeMap[themeName].favicon} />
      {product && <title>{titleCase(product)} | {themeMap[themeName].title}</title>}
      {!product && <title>{themeMap[themeName].title}</title>}
    </Helmet>
  );
};
