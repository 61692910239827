/* eslint-disable no-use-before-define */
import React from "react";
import { Box } from "@rtm-ui/layout";
import { Form } from "@rtm-ui/form";
import { track } from '@rtm-ui/tracker';
import { AppStateContext, AppDispatchContext } from "../app-context";
import { getFields, campaignQuery, sendSurvey, getUserInfo } from "../handlers";
import { saveSelectedData } from "../common/userProfile";
import styled from "styled-components";

export const FormWrapper = styled(Box)`
  width: 100%;
  background: white;

  & > * {
    background: white;
    margin: 0 auto;
    width:  ${props => props.optionsCount === 4? '300px': '380px'}; // slightly narrower in 4 to give a 2X2 grid
  }
`;

export const Default = ({ formRef: ref, campaignType }) => {

  const state = React.useContext(AppStateContext);
  const dispatch = React.useContext(AppDispatchContext);

  const currentProduct = state.currentProduct;
  const currentPostcodeSuburb = state.user && state.user.postcodeSuburb;
  const [optionsCount, setOptionsCount] = React.useState(0);

  React.useEffect(() => {
    const prepareForm = async () => {
      if (currentProduct) {
        dispatch({ type: "formBuilding" });
        try {
          const fields = await getFields(dispatch, currentProduct, currentPostcodeSuburb, state.user.email, campaignType);
          if (fields.length === 0) {
            runCampaignQuery()
            dispatch({ type: "formReady", formReady: true });
          } else {
            dispatch({ type: "form1Ready", fieldset1: [fields[0]] });
          }
        } catch (error) {
          dispatch({ type: "formFailed" });
        }
      }
    };
    prepareForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runCampaignQuery, currentProduct]);


  // Memoized campaign query
  const runCampaignQuery = React.useCallback(
    async (values = []) => {
      if (values.length > 0) {
        const data = {
          email: state.user.email,
          data: {
            [`${currentProduct}OfferType`]: values[0].value,
          }
        }
        const user = await getUserInfo(state.userApiAuthToken)
        if (user && !user.is_suppressed) {
          sendSurvey(data);
        }
      }
      campaignQuery(dispatch, state, values);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProduct]
  );


  const callTracker = (field, value) => {  //
    const action = `${currentProduct}_offer_tile_button`;
    const category = 'default';
    const meta = {
      ['offer_type']: value,
      defaultProduct: currentProduct,
    };
    track( action, { category, meta });
  }

  React.useEffect(() => {
     // if only one options run campaign query
  if (state.form1Ready) {
    const fields = state.form1.fields;
    if (fields.length  === 1) {
       const options = fields[0].options;
       if (options.length === 1 ) {
         runCampaignQuery(options);
       }
        setOptionsCount(options.length)
    }
   }
  }, [state.form1Ready]);

  return (
    <FormWrapper optionsCount={optionsCount} px={[0, 2]} py={10} >
      {/* For now set this so it matches up with plan cta right-align */}
      <Box px={[2]} py={30}>
        {state.form1Ready && (
          <>
            <Form
              {...state.form1}
              progressiveReveal={true}
              renderFooter={() => null}
              autoSearch
              getNewestFieldValue = {(field, value) => {
                callTracker(field,value);
                saveSelectedData(field, value, state.campaign_id, state.userToken)
              }}
              onSubmit={async values => {
                runCampaignQuery(values);
                const fields = values;
                fields[0].defaultValue = values[0].value;
                dispatch({ type: "formReady", formReady: true, fields });
                return values;
              }}
            />
          </>
        )}
      </Box>
    </FormWrapper>
  );
};
