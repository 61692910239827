import React from "react";
import { AppStateContext, AppDispatchContext } from "../app-context";
import * as S from "./styles";
import { campaignQuery } from "../handlers";

export const Life = ({ formRef: _ref }) => {

  const state = React.useContext(AppStateContext);
  const dispatch = React.useContext(AppDispatchContext);

  const currentProduct = state.currentProduct;
  const currentPostcodeSuburb = state.user && state.user.postcodeSuburb;

  React.useEffect(() => {
    if (currentProduct) {
      campaignQuery(dispatch, state);
    }
  }, [currentPostcodeSuburb, currentProduct]);

  return (
    <S.FormWrapper px={[0, 2]} py={10} />
  );
};
