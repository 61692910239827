import { Router, useLocation } from "@reach/router";
import { ErrorBoundary } from "@rtm-ui/error-boundary";
import { Footer } from "@rtm-ui/footer";
import { useLocale } from "@rtm-ui/i18n";
import { Img } from "@rtm-ui/img";
import { Nav } from "@rtm-ui/nav";
import { themeMap } from "@rtm-ui/theme";
import { TrackerRegistration } from "@rtm-ui/tracker";
import { Header, Markdown } from "@rtm-ui/typography";
import React from "react";
import "react-app-polyfill/stable";
import { useCookies } from "react-cookie";
import { AppDispatchContext, AppStateContext } from "./app-context";
import { BroadbandAndMobile } from "./broadbandAndMobile";
import { Offer } from "./common/offer";
import { Default } from "./default";
import { Energy } from "./energy";
import { getAuthToken, getMeta, getTheme, getUserSession, getUserToken } from "./handlers";
import { Health } from "./health";
import { HomeAndCar } from "./homeAndCar";
import { Life } from "./life";
import { Main } from "./main";
import Dashboard from "./pages/dashboard";
import NotFound from "./pages/not_found";
import { Seo } from "./seo";
import * as S from "./styles";
import { fieldLabels } from "./utils";

const appReducer = (state, action) => {
  /* console.log(`[REDUCER] - type: ${action.type}`, action, state); */
  switch (action.type) {
    case "initialize":
      return {
        ...state,
        ready: true,
        theme: action.theme,
        user: action.user,
        userToken: action.userToken,
        userApiAuthToken: action.userApiAuthToken,
        meta: action.meta,
        postcodeUrl: action.meta.entity.postcode_api_url,
        postcodeForm: {
          ...state.postcodeForm,
          fields: action.postcodeForm
        }
      };
    case "failedInitialize":
      return {
        ...state,
        failed: true
      };
    case "formFocused":
      return {
        ...state,
        campaign: {}
      };
    case "setPostcode":
      return {
        ...state,
        postCodeMessage: "Fetching Form....",
        trackingMeta: null,
        user: { ...state.user, postcodeSuburb: action.value, state: action.state }
      };
    case "setUserProfile":
      return {
        ...state,
        campaign_id: action.campaign_id
      };
    case "postCodeEmptyMessage":
      return { ...state, postCodeMessage: "" };
    case "formBuilding":
      return {
        ...state,
        formReady: false,
        form1Ready: false,
        offerReady: false,
        offerErrorMessage: "",
        form: { ...state.form, fields: [] },
        customerForm: { ...state.form, fields: [] }
      };
    case "postcodeSelected":
      return { ...state, form: { ...state.form, fields: [] } };
    case "formReady":
      return {
        ...state,
        formReady: action.formReady,
        offerErrorMessage: null,
        form: { ...state.form, fields: action.fields }
      };
    case "form1Ready":
      return {
        ...state,
        form1Ready: true,
        formReady: false,
        fieldset2: action.fieldset2,
        postCodeMessage: null,
        offerErrorMessage: null,
        form1: { ...state.form, fields: action.fieldset1 }
      };
    case "formFailed":
      return {
        ...state,
        user: { ...state.user },
        scrollPoint: null,
        formReady: false,
        form1Ready: false,
        offerReady: false,
        postCodeMessage: null,
        offerErrorMessage:
          `#### Sorry no plans available for this ${fieldLabels[state.theme.countryCode].friendlyname}.`
      };
    case "resetOffer":
      return {
        ...state,
        user: { ...state.user },
        scrollPoint: null,
        formReady: false,
        form1Ready: false,
        offerReady: false,
        postCodeMessage: null,
        offerErrorMessage: null,
        form: {},
        trackingMeta: null,
      };
    case "quickSearch":
      return {
        ...state,
        formReady: true,
        searching: true,
        form: { ...state.form, fields: action.fields }
      };
    case "searching":
      return { ...state, searching: true };
    case "offerVisible":
      return { ...state, scrollPoint: null };
    case "offerNotAvailableForExistingCustomers":
      return {
        ...state,
        searching: false,
        offerReady: false,
        formReady: false,
        postCodeMessage: null,
        offerNotAvailableForCustomer: true,
        offerErrorMessage: action.offerErrorMessage
      };
    case "offerNotAvailable":
      return {
        ...state,
        searching: false,
        offerReady: false,
        offerErrorMessage:
          "#### Sorry no plans available in your area."
      };
    case "offerReady":
      return {
        ...state,
        offerReady: action.offerReady,
        searching: false,
        scrollPoint: "offer",
        campaign: action.campaign || {},
        references: {
          ...action.zoneData,
          ...action.campaign.reference,
          campaign: action.campaign || {},
          entity: state.meta.entity || {},
        }
      };
    case "currentProduct":
      return {
        ...state, ...action.value
      };
    case "trackingMeta":
      return {
        ...state, ...{ trackingMeta: action.trackingMeta }
      };
    case "authToken": {
      return {
        ...state,
        user: { ...state.user, authToken: action.auth_token }
      };
    }
    default:
      /* FIXME: something is causing a new state pointer to screenjump */
      return state;
  }
};

const SearchingBox = ({ isSearching, themeName, children }) => {
  const theme = themeMap[themeName || 'obs'];

  return (
    <S.SearchBoxWrapper>
      {/* TODO: toggle this when is-searching */}
      {isSearching && (
        <S.SearchBox>
          <S.LoadingPageWrapper>
            <S.LoadingPageMiddle>
              <S.LoadingPageInner>
                <Img src={theme.loadingLogo} width={250} />
              </S.LoadingPageInner>
            </S.LoadingPageMiddle>
          </S.LoadingPageWrapper>
        </S.SearchBox>
      )}
      {!isSearching && children}
    </S.SearchBoxWrapper>
  );
};

const LoadingPage = ({ imageUrl }) => (
  <S.LoadingPageWrapper>
    <S.LoadingPageMiddle>
      <S.LoadingPageInner>
        <Img src={imageUrl} />
      </S.LoadingPageInner>
    </S.LoadingPageMiddle>
  </S.LoadingPageWrapper>
);

export function App(props) {
  const { errorMessage } = useLocale({ key: "errorMessage" });
  const [cookies] = useCookies();

  const [state, dispatch] = React.useReducer(appReducer, {
    user: false,
    theme: false,
    ready: false,
    failed: false,
    meta: {},
    references: {},
    form: {
      id: "plan_query",
      fields: []
    },
    customerForm: {
      id: "customer_query",
      fields: [],
    },
    postcodeForm: {
      id: "field_query",
      fields: []
    },
    campaign: {}
  });

  const formRef = React.useRef();
  const offerRef = React.createRef();

  React.useEffect(() => {
    async function prepareApp() {
      const user = await getUserSession(cookies);
      const userToken = await getUserToken();
      const userApiAuthToken = await getAuthToken(user);
      const theme = await getTheme();
      const product = window.location.pathname.split("/").slice(-1);
      const meta = await getMeta(product);

      if (!user || !meta) {
        window.location = `${window.location.protocol}//${window.location.host}`;
        return;
      } else {
        dispatch({
          type: "initialize",
          user,
          userToken,
          userApiAuthToken,
          theme,
          meta
        });
      }
    }
    prepareApp();
  }, [cookies, state.theme.countryCode]);

  React.useEffect(() => {
    if (offerRef.current && state.scrollPoint === "offer") {
      offerRef.current.scrollIntoView({ behavior: "smooth" });
      dispatch({ type: "offerVisible" });
    }
  }, [offerRef, state.scrollPoint]);

  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname.replace(/[^\w\s]/gi, '') === 'offers') {
      dispatch({ type: "resetOffer" });
    }
  }, [location.pathname]);

  console.log('app state: ', state);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        <Main
          themeName={state.theme.themeName}
          trackingData={{
            category: state.currentProduct,
            meta: {
              ...state.campaign.tracking_meta
            },
            authenticityToken: state.campaign.authenticity_token
          }}
        >
          <ErrorBoundary
            renderOnError={<Header p={[10, 20]}>{errorMessage}</Header>}
          >
            {/* TODO: Move these various statuses (failed, ready, searching) into a single 'status' value */}
            {state.failed ? (
              "Failed to initialize"
            ) : state.ready ? (
              <>
                <Seo product={state.currentProduct} themeName={state.theme.themeName} />
                <TrackerRegistration {...state.meta.entity} user={state.user} />
                <Nav
                  signOutPath={state.meta.entity.navigation_items.signOutPath}
                  user={{ email: state.user.email }}
                  onHomeClick={() => console.log("/")}
                  tagline={state.meta.entity.navigation_items.tagline}
                  isClosed={true}
                  items={state.meta.entity.navigation_items.items}
                />
                <Router>
                  <Dashboard path="offers" user={state.user} >
                    {/* this also need route to /energy
                    currentProduct is set from Dashboard
                    */}
                    <Energy path="energy/" product="energy" formRef={formRef} />
                    <Energy path="energy/:campaignType" product="energy" formRef={formRef} />
                    <HomeAndCar path="home/" product="home" formRef={formRef} />
                    <HomeAndCar path="car/" product="car" formRef={formRef} />
                    <Health path="health/" product="health" formReady={formRef} />
                    <Life path="life/" product="life" formReady={formRef} />
                    <BroadbandAndMobile path="broadband/" product="broadband" formReady={formRef} />
                    <BroadbandAndMobile path="mobile/" product="mobile" formReady={formRef} />
                    <Default default product=":product" formReady={formRef} />
                  </Dashboard>
                  <NotFound default />
                </Router>
                <SearchingBox themeName={state.theme.themeName} isSearching={state.searching}>
                  {state.offerReady
                    ? Object.keys(state.campaign).length > 0 && (
                      <div ref={offerRef}>
                        <Offer
                          {...state.campaign}
                          state={state}
                          formRef={formRef}
                        />
                      </div>
                    )
                    : state.offerErrorMessage &&
                    <S.InfoContainer p="4">
                      <Markdown raw={state.offerErrorMessage || ''} />
                    </S.InfoContainer>
                  }
                </SearchingBox>

                <Footer entity={state.meta.entity} disclaimers={[]} />
              </>
            ) : (
              <LoadingPage imageUrl="https://digital-au.s3.ap-southeast-2.amazonaws.com/web/LOADING-UNLOCK-ALL-up.gif" />
            )}
          </ErrorBoundary>
        </Main>
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}
